import request from '@/plugins/axios'

// ================推特帐号接口==============
export function getTwitterList(params) {
  // 获取twitter帐号列表
  return request({
    url: '/twitter/accounts',
    method: 'get',
    params
  })
}
// ================facebook帐号接口==============
export function getFacebookList(params) {
  return request({
    url: '/facebook/accounts',
    method: 'get',
    params
  })
}
// ================youtube帐号接口==============
export function getYoutubeList(params) {
  return request({
    url: '/youtube/accounts',
    method: 'get',
    params
  })
}
// ================获取用户自己积分详情==============
export function getUserPoints(pageIndex, pageSize) {
  return request({
    url: '/service/points_historys',
    method: 'get',
    params: {
      pageIndex,
      pageSize
    }
  })
}
// ================头部6个数据==============
export function getSummary() {
  return request({
    url: '/dashboard/summary',
    method: 'get'
  })
}
export function getMyMonitorTask() {
  return request({
    url: '/dashboard/post_task',
    method: 'get'
  })
}
// ================任务统计类别==============
export function categoryApi() {
  return request({
    url: '/dashboard/task_category',
    method: 'get'
  })
}
// ================任务状态统计==============
export function taskStatusApi() {
  return request({
    url: '/dashboard/task_status',
    method: 'get'
  })
}
